import Vue from 'vue'
import App from './App.vue'
import router from './router';
import "./assets/scss/main.scss";
import './assets/fonts/fontawesome-all.min.css';

import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
import 'aos/dist/aos.css'



//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
