import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';

Vue.use(VueRouter);

const routes = [
    //dashboard
    { path: '/', name: 'Home', component: Home },
    //components
    {
        path: '/handleProduct',
        name: 'handleProduct',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/handleProduct.vue')
    },
    {
        path: '/comingSoon',
        name: 'comingSoon',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/coming_soon.vue')
    },


];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});



export default router;
