<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script >
import '@/assets/sass/app.scss';
import AOS from 'aos/dist/aos.js'
export default {
  name: 'App',
  mounted() {
// 你也可以在这里设置全局配置
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
