<template>
  <div class=" row" style="height: 100%">

    <!--  BEGIN NAVBAR  -->
    <div  class="col-12" style="padding: 146px 32px">
      <h1  data-aos="fade-up" class="tagline">一家
          <span class="accent" >还不错的</span>
          <br>
        科技公司
      </h1>
      <p class="description" data-aos="fade-up" data-aos-delay="150"> 面向企业、商户、学校等社会各界组织提供高性能、低价格的基础设施级服务. </p>
      <p class="indexButton" style="margin: 0"  data-aos="fade-up">
        <b-button  size="lg" variant="primary"   href="mailto:qiyunoil@aliyun.com"  class="mb-2 mr-4" >联系我们</b-button>
        <b-button variant="outline-primary"   class="mb-2 mr-1" size="lg" @click="handleProduct">最新产品</b-button>
      </p>
    </div>

    <footer class="position-absolute bottom-0 left-0">
      <!--footer bottom start-->
      <div class="footer-bottom bg-white text-white  ">
        <div class="container" >
          <div class="row  flex-row-reverse justify-content-between align-items-center" style="margin-bottom: 10px">
            <div class="col-md-4 col-lg-4">
              <div class="footer-single-col text-start text-lg-end text-md-end">
                <ul class="list-unstyled list-inline  mb-0">
                  <li class="list-inline-item" >
                    <a href="https://beian.miit.gov.cn/" target="_blank"><span class="bottom-right-text" style="">备案号:晋ICP备2021012726号</span> </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5 col-lg-7">
              <div class="copyright-text" >
                <p class="mb-lg-0 mb-md-0" >
                  Copyright © 2022-2023山西栖云科技有限公司. All rights reserved
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--footer bottom end-->
    </footer>

  </div>
</template>
<script>

export default {
  data() {
    return {
      selectedLang: null,
    };
  },
  mounted() {
      this.show = true

  },
  methods: {

    handleProduct(){
        this.$router.push({path:'/handleProduct'})
    }


  }
};
</script>
<style lang="scss">

@media (max-width: 575px) {
  .copyright-text{
    p{
      font-size: 12px !important;
      margin: 0;
    }
  }
  .bottom-right-text{
    font-size: 12px !important;
    color: #65676d;
  }
  .tagline{
    font-size: 40px !important;
    color: #0a0d26 !important;
    .accent{
      background: -webkit-linear-gradient(315deg,#1abc9c 25%,#4361ee) ;
      -webkit-background-clip:text;
      -webkit-text-fill-color: transparent;
    }
  }
  .description{
    line-height: 1.5;
    transition: color .5s;
    font-size: 15px !important;
    margin: 24px auto 60px;
  }
  .indexButton{
    a{
      --bs-btn-padding-y:4px;
      --bs-btn-padding-x:16px;
      font-size:15px
    }
    button{
      --bs-btn-padding-y:4px;
      --bs-btn-padding-x:16px;
      font-size:15px
    }
  }
}

@media (max-width: 767px) {
  .copyright-text{
    p{
      font-size: 12px !important;
      margin: 0;
    }
  }
  .bottom-right-text{
    font-size: 12px !important;
    color: #65676d;
  }
  .tagline{
    font-size: 76px;
    line-height: 1.25;
    font-weight: 900;
    letter-spacing: -1.5px;
    max-width: 960px;
    margin: 0 auto;
    color: #0a0d26;
    .accent{
      background: -webkit-linear-gradient(315deg,#1abc9c 25%,#4361ee) ;
      -webkit-background-clip:text;
      -webkit-text-fill-color: transparent;
    }
  }
  .description{
    line-height: 1.5;
    transition: color .5s;
    font-size: 22px;
    margin: 24px auto 60px;
  }
}

@media (max-width: 1199px) {
  .tagline{
    font-size: 76px;
    line-height: 1.25;
    font-weight: 900;
    letter-spacing: -1.5px;
    max-width: 960px;
    margin: 0 auto;
    color: #0a0d26;
    .accent{
      background: -webkit-linear-gradient(315deg,#1abc9c 25%,#4361ee) ;
      -webkit-background-clip:text;
      -webkit-text-fill-color: transparent;
    }
  }
  .description{
    line-height: 1.5;
    transition: color .5s;
    font-size: 22px;
    margin: 24px auto 60px;
  }
}




.tagline{
  font-size: 76px;
  line-height: 1.25;
  font-weight: 900;
  letter-spacing: -1.5px;
  max-width: 960px;
  margin: 0 auto;
  color: #0a0d26;
  .accent{
    background: -webkit-linear-gradient(315deg,#1abc9c 25%,#4361ee) ;
    -webkit-background-clip:text;
    -webkit-text-fill-color: transparent;
  }
}
.description{
  line-height: 1.5;
  transition: color .5s;
  font-size: 22px;
  margin: 24px auto 60px;
}
.bottom-right-text{
  font-size: 14px;
  color: #65676d;
}
</style>
